// extracted by mini-css-extract-plugin
export var featuredWrapper = "index-module--featuredWrapper--ef9bd";
export var fromMyBlog = "index-module--fromMyBlog--2dd82";
export var hello = "index-module--hello--81edc";
export var latestTalk = "index-module--latestTalk--edccf";
export var learnMore = "index-module--learnMore--21502";
export var profile = "index-module--profile--2f8f7";
export var recipe = "index-module--recipe--cba2b";
export var talkDate = "index-module--talkDate--fac6a";
export var talkEvent = "index-module--talkEvent--1fbe0";
export var talkMetadata = "index-module--talkMetadata--b1769";
export var whoami = "index-module--whoami--9677a";